import React from 'react'

const Button = ({type="button",paddingClass="px-5 p-3",children,text="",onClick=()=>{},className="",filled=true,...props}) => {
  return (
    <button onClick={onClick} {...props} className={ `${paddingClass} rounded ${filled?"outline-button-filled": "outline-button"}  ${className}`}>
      {children}
    </button>
  )
}

export default Button