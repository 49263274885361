import React, { useEffect, useContext, useState, Suspense, lazy, useRef, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { UsersContext } from "../../context/usersContext";
import useReq from "../../hook/useReq";
import "../../App.css";
import "../Chat/styles/main.css";
import "./styles/main.css";
import cn from "classnames";
import Swal from "sweetalert2";
// import defaultAvatar from "../../assets/icons/default_profile.jpg";
import { CgClose } from "react-icons/cg";
import Loader from "../../components/Loader";
import LazyImage from "../../components/LazyImage";
import { MdGroups, MdNavigateNext, MdOutlineGroupAdd } from "react-icons/md";
import { useWindowWidth } from "@react-hook/window-size";
import PageLoader from "../../components/Loader/PageLoader";
import Button from "../../components/Button";
import { IoPersonRemove } from "react-icons/io5";

const Chatapp = lazy(() => import("../Chat/chatapp"));
const FileManager = lazy(() => import("./FileManager"));

const Project = ({ match }) => {
  const { projectId } = useParams();
  sessionStorage.setItem("projectid", projectId);
  const navigate = useNavigate();
  const ctx = useContext(UsersContext);
  const { socket } = ctx;
  const [data, setData] = useState(null);
  const [documentsNavItem, setDocumentsNavItem] = useState(sessionStorage.getItem("nav") ? sessionStorage.getItem("nav") === "doc" : true);
  const [communicationNavItem, setCommunicationNavItem] = useState(sessionStorage.getItem("nav") === "com");
  const [inviteMembersVisibility, setInviteMembersVisibility] = useState(false);
  const [viewMembersVisibility, setViewMembersVisibility] = useState(false);
  const [inviteMembers, setInviteMembers] = useState([]);
  const [projectAdmin, setProjectAdmin] = useState(null);
  const [allUsers, setAllUsers] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchError, setSearchError] = useState("");
  const [filteredData, setFilteredData] = useState(null);
  const [isProjectHeaderOpen, setIsProjectHeaderOpen] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [scrolling, setScrolling] = useState(false);
  const screenWidth = useWindowWidth();

  const debounceTimer = useRef(null);

  // Debounced playSound function

  // const { clear, response, error, requestData } = useReq();

  // **** All API Instanses

  // Invite Members API instance
  const { requestData: requestUsersToBeAdd, response: responseUsersToBeAdded, clear: clearUsersToBeAdded, loading: loadingUsersToBeAdded } = useReq();

  // Add Projects User
  const { clear: clearAllProjectUser, response: responseAllProjectUser, error: errorAllProjectUser, requestData: requestAllProjectUser } = useReq();

  // Project Info API Instance
  const { clear: clearProjectInfo, response: responseProjectInfo, error: errorProjectInfo, requestData: requestProjectInfo } = useReq();

  // Invite to Project API Instance
  const { clear: clearInviteUser, response: responseInviteUser, error: errorInviteUser, requestData: requestInviteUser } = useReq();
  const { clear: clearRemoveMember, response: responseRemoveMember, error: errorRemoveMember, requestData: requestRemoveMember,loading:loadingRemoveMember } = useReq();

  // ********* All API's

  // Fetch members to be added to the project

  useEffect(() => {
    if(responseRemoveMember && responseRemoveMember.success){
      requestProjectInfo("GET", `/user/projects/${projectId}`);
      requestAllProjectUser("GET", `/user/viewallprojectusers/${projectId}`);
      Swal.fire({text:"Member Removed Successfully",icon:"success",iconColor:"#0a5c7b",confirmButtonColor:"#8dd892"});
    }
  }, [responseRemoveMember]);

  useEffect(() => {
    if(errorRemoveMember){
      Swal.fire({text:"Member Removed Failed",icon:"failed",iconColor:"#0a5c7b"});
    }
  }, [errorRemoveMember]);

  
  const removeMember = (email) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won’t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Remove it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        requestRemoveMember("DELETE", `/user/project/member/${projectId}`, {email})
      }
    });
  }
  const inviteMembersApi = () => {
    requestUsersToBeAdd("POST", `/user/getuserstobeaddedtoproject/${projectId}`);
    setInviteMembersVisibility(true);
  };

  // Fetch all project members
  const viewMembersApi = () => {
    requestProjectInfo("GET", `/user/projects/${projectId}`);
    requestAllProjectUser("GET", `/user/viewallprojectusers/${projectId}`);
    setViewMembersVisibility(true);
  };

  // Fetch project data
  useEffect(() => {
    requestProjectInfo("GET", `/user/projects/${projectId}`);
  }, [projectId]);

  // Handle member invitation
  const handleInvite = () => {
    if (inviteMembers.length === 0) {
      return;
    }

    const jsonArray = JSON.stringify(inviteMembers.map((user) => user.email));

    requestInviteUser("POST", `/user/inviteMembers/${projectId}`, {
      users: jsonArray,
    });
  };

  // Handle search query change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  // Debounced search effect & Search
  useEffect(() => {
    const handleDebounce = setTimeout(() => {
      if (searchQuery !== "") {
        requestUsersToBeAdd("POST", `/user/getuserstobeaddedtoproject/${projectId}`, {
          search: searchQuery,
        });
      } else {
        requestUsersToBeAdd("POST", `/user/getuserstobeaddedtoproject/${projectId}`, {
          currentPage,
        });
      }
    }, 500);

    return () => clearTimeout(handleDebounce);
  }, [searchQuery, currentPage]);

  // Handle next page API call
  const NextPage = () => {
    requestUsersToBeAdd("POST", `/user/getuserstobeaddedtoproject/${projectId}`, {
      currentPage: currentPage + 1,
    });
  };

  // Toggle Project Header
  const toggleAccordion = () => {
    setIsProjectHeaderOpen(!isProjectHeaderOpen);
  };

  const playSound = useCallback((data) => {
    if (data.unread > 0) {
      const audioElement = new Audio("/assets/sound/notify.mp3");
      // audioElement.muted = true;
      audioElement.play();
    }
  }, []);

  const debouncedPlaySound = (data) => {
    // Clear the previous timer if it exists
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    // Set a new timer
    debounceTimer.current = setTimeout(() => {
      playSound(data);
    }, 300); // 300 milliseconds debounce delay
  };

  // Listen to socket events
  useEffect(() => {
    if (socket) {
      socket.on("forSidebar", debouncedPlaySound);

      return () => {
        socket.off("forSidebar", debouncedPlaySound);
      };
    }
  }, [socket]);

  // Handle scroll event for Full screen
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Handle project response Info & project admin response
  useEffect(() => {
    if (responseProjectInfo !== null && responseProjectInfo.project) {
      setData(responseProjectInfo.project);
      setProjectAdmin((prev) => responseProjectInfo.project.projectadmin);
    }

    if (responseProjectInfo !== null && responseProjectInfo.projectadmin) {
      setProjectAdmin(responseProjectInfo.projectadmin);
    }
  }, [responseProjectInfo]);

  // handel project All  Users Response
  useEffect(() => {
    if (responseAllProjectUser !== null && responseAllProjectUser.allUsers) {
      setAllUsers(responseAllProjectUser.allUsers);
    }
  }, [responseAllProjectUser]);

  // Handle response for users to be added
  useEffect(() => {
    if (responseUsersToBeAdded !== null && responseUsersToBeAdded.allUsers) {
      setTotalPages(responseUsersToBeAdded.totalPages);
      setCurrentPage(responseUsersToBeAdded.currentPage);

      const filteredUsers = responseUsersToBeAdded.allUsers.filter((user) => !inviteMembers.some((invited) => invited.email === user.email));

      setFilteredData((prevFilteredData) => {
        if (searchQuery.length > 0 || responseUsersToBeAdded.currentPage === 1) {
          return filteredUsers;
        } else {
          const newFilteredData = [...prevFilteredData, ...filteredUsers].filter((user, index, self) => index === self.findIndex((item) => item.email === user.email));
          return newFilteredData;
        }
      });
    }
  }, [responseUsersToBeAdded, searchQuery, inviteMembers]);

  // Handle project not found error
  useEffect(() => {
    if (errorProjectInfo !== null && errorProjectInfo.message === "Project not found") {
      navigate("/404");
    }
  }, [errorProjectInfo]);

  // Handle navigation clicks for Documents & Communication
  const handleClick = (e) => {
    const targetElementID = e.target.id;
    if (targetElementID === "Documents") {
      setDocumentsNavItem(true);
      setCommunicationNavItem(false);
      sessionStorage.setItem("nav", "doc");
    }
    if (targetElementID === "Communication") {
      sessionStorage.setItem("nav", "com");
      setDocumentsNavItem(false);
      setCommunicationNavItem(true);
    }
  };

  // Format date function
  function formatDate(inputDate) {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    const formattedDate = new Date(inputDate).toLocaleDateString("en-US", options);
    return formattedDate;
  }

  // Detect enter key press for search user email
  const detectEnterPress = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(searchQuery)) {
        setSearchError("Please enter a valid email address!");
        return;
      }
      const searchUser = filteredData.find((item) => item.email === searchQuery);

      if (searchUser) {
        handleAdd(searchUser);
        setSearchQuery("");
        setSearchError("");
      } else {
        handleAdd({
          email: searchQuery,
          avatar: "/assets/icons/default_profile.jpg",
        });
        setSearchQuery("");
        setSearchError("");
      }
    }
  };

  // Handle member invitation success/error
  useEffect(() => {
    if (responseInviteUser !== null && responseInviteUser.invited) {
      setInviteMembersVisibility(false);
      setInviteMembers([]);
      // const index = document.getElementById("zindex");
      // index.classList.remove("c-z-index");

      Swal.fire({
        title: "",
        text: "Invited Successfully !",
        icon: "success",
        iconColor: "#0a5c7b",
        showConfirmButton: false,
      });

      setTimeout(function () {
        Swal.close();
        // index.classList.add("c-z-index");
        requestProjectInfo("GET", `/user/projects/${projectId}`);
      }, 1200);
    }
  }, [responseInviteUser]);

  useEffect(() => {
    if (errorInviteUser) {
      Swal.fire({
        title: "",
        text: "Failed to invite members. Please try again later.",
        icon: "error",
        iconColor: "red",
        showConfirmButton: true,
        confirmButtonText: "OK",
        confirmButtonColor: "#0a5c7b",
      });
    }
  }, [errorInviteUser]);

  // All user Data fetching error handling
  useEffect(() => {
    if (errorAllProjectUser) {
      if (errorAllProjectUser) {
        Swal.fire({
          title: "",
          text: "Failed to fetching Members. ",
          icon: "error",
          iconColor: "red",
          showConfirmButton: true,
          confirmButtonText: "Try Again..",
          confirmButtonColor: "#0a5c7b",
          preConfirm: fetchMemberAgain,
        });
      }
    }
  }, [errorAllProjectUser]);

  const fetchMemberAgain = () => {
    requestAllProjectUser("GET", `/user/viewallprojectusers/${projectId}`);
  };

  useEffect(() => {
    if (screenWidth < 767) {
      setIsProjectHeaderOpen(false);
    }
  }, [screenWidth]);

  // Handle member addition
  const handleAdd = (user) => {
    const exists = inviteMembers.some((entry) => {
      return entry.email === user.email;
    });
    if (exists) {
      setSearchQuery("");
      return;
    }
    setInviteMembers([...inviteMembers, user]);
    setFilteredData(filteredData.filter((item) => item.email !== user.email));
  };

  // Handle member removal
  const handleRemove = (user) => {
    setInviteMembers((prev) => prev.filter((item) => item.email !== user.email));
    const updatedFilteredData = [...filteredData, user];
    setFilteredData(updatedFilteredData);
  };

  const inputDate = data ? data.created_at : "";
  const formattedDate = formatDate(inputDate);

  // Handle component resize
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768 && !document.getElementById("collapseOne").classList.contains("show")) {
        document.getElementById("collapseOne").classList.add("show");
      }
    };

    window.addEventListener("load", handleResize);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("load", handleResize);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {viewMembersVisibility && (
        <>
          <div className="invite-members__overlay position-fixed h-100 w-100 top-0 start-0 bg-transparent d-flex justify-content-center align-items-center cmm-z-index" onClick={() => setViewMembersVisibility(false)} style={{ backdropFilter: "brightness(0.7)" }}></div>
          <div className="invite-members__content position-fixed mb-5 top-50 start-50 translate-middle w-50 c-w-100 shadow-lg bg-white rounded cc-z-index p-10 popup-model" style={{ height: "500px" }}>
            <div className="d-flex">
              <h5 className="mb-5 fs-2 bluecolor">All Members </h5>
              <a
                className="c-close fw-bolder ms-auto"
                href="javascript: void(0)"
                onClick={() => {
                  setViewMembersVisibility(false);
                }}
              >
                <img src="/assets/icons/close-blue.png" height={16} width={16} className="me-3" />
              </a>
            </div>
            <div className="mh-400px flex-1 overflow-y-scroll">
              <div>
                <>
                  <div className="col-12">
                    <div className="form-group">
                      {/* {searchError && (
                        <p style={{ color: "red" }}>{searchError}</p>
                      )} */}

                      {/* <div> */}
                      <div className="mt-4">
                        {/* <div style={{ overflow: "scroll" }}> */}
                        {!allUsers && <Loader />}
                        {allUsers &&
                          allUsers.map((item) => (
                            <div key={item._id} className="d-flex align-items-center py-4 px-6 invite allmember-mobile" style={{ cursor: "auto" }}>
                              <LazyImage key={item.id} src={process.env.REACT_APP_SOCKETIO + item.avatar} />
                              <div className="d-flex flex-column w-100">
                                <div className="d-flex justify-content-between align-items-center">
                                <span className="fs-4">{item.username}</span>
                                {!item.isAdmin && projectAdmin === ctx.email && <Button onClick={()=>removeMember(item.email)} className="fs-7" paddingClass="p-1 px-3" >Remove</Button>}
                                </div>
                                <span className="fs-6">{item.email}</span>
                                <div className="d-flex justify-content-between align-items-center">
                                <span className="fs-7">{item.role}</span>
                                  <span className="fs-7">{item.isAdmin ? "Host" : "Member"}</span>
                                </div>
                              </div>
                            </div>
                          ))}
                        {/* </div> */}
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        </>
      )}
      {inviteMembersVisibility && (
        <>
          <div className="invite-members__overlay position-fixed h-100 w-100 top-0 start-0 bg-transparent d-flex justify-content-center align-items-center cmm-z-index" onClick={() => setInviteMembersVisibility(false)} style={{ backdropFilter: "brightness(0.7)" }}></div>
          <div className="invite-members__content position-fixed top-50 start-50 translate-middle h-600px w-50 c-w-100 shadow-lg bg-white rounded cc-z-index p-10 popup-model">
            <a
              className="c-close position-absolute fw-bolder cursor-pointer"
              onClick={() => {
                setInviteMembersVisibility(false);
                setInviteMembers([]);
                // setSearchInvite("");
                setSearchQuery("");
                setCurrentPage(1);
                setFilteredData([]);
              }}
            >
              <img src="/assets/icons/close-blue.png" height={16} width={16} className="me-3" />
            </a>
            <div className="col-12">
              <div className="form-group mt-4">
                <label className="pb-3 mt-3 fw-bold fs-2 bluecolor" htmlFor="members">
                  Invite Users
                </label>

                <Button onClick={handleInvite} className="mb-5 fs-4" style={{ float: "right" }} disabled={inviteMembers.length === 0}>
                  <img src="/assets/icons/send-invite.png" height={20} width={20} className="me-3" />
                  Send Invite
                </Button>
                {searchError && <p style={{ color: "red" }}>{searchError}</p>}
                <input type="text" className="form-control" id="members" placeholder="example@gmail.com / Username" style={{ borderRadius: "10px 10px 0 0" }} value={searchQuery} onChange={handleSearchChange} autoFocus autoComplete="off" onKeyDown={detectEnterPress} />
              </div>
            </div>
            <h5 className="my-6">Select below Users to be invited</h5>
            <div className="container h-75 overflow-scroll">
              <div className="row">
                <div className="mt-4 ">
                  <div>
                    {inviteMembers.length > 0 &&
                      inviteMembers.map((entry, id) => (
                        <div key={entry._id} className="d-flex align-items-center invite-selected  py-3 mb-4" onClick={() => handleRemove(entry)}>
                          <LazyImage key={entry.id} src={process.env.REACT_APP_SOCKETIO + entry.avatar} />
                          <div className="d-flex flex-column">
                            <span className="fs-5 ms-5">
                              {entry.username} ({entry.email})
                            </span>

                            <span className="fs-8 ms-5">{entry.role}</span>
                          </div>
                          <CgClose className=" ms-auto fs-3 me-4" />
                        </div>
                      ))}
                    {filteredData && filteredData.length === 0 && inviteMembers < 1 && (
                      <div className="border border-gray-300 p-2  rounded px-5 bg-gray-200i mt-1 d-flex justify-content-center flex-column align-items-center " style={{ width: "100%", overflow: "scroll" }}>
                        <b>No users are avaliable to invite.</b>
                        <b>You can just type their email and press Enter to add them.</b>
                        <img src="/assets/icons/users-avatar.png" height={150} width={150} alt="no Users" />
                      </div>
                    )}

                    {!filteredData && <Loader />}

                    {filteredData &&
                      filteredData
                        .sort((a, b) => a.username?.localeCompare(b.username))
                        .map((item) => {
                          return (
                            <div
                              key={item._id}
                              className="d-flex align-items-center invite py-3 mb-4"
                              onClick={() => {
                                handleAdd(item);
                              }}
                            >
                              <LazyImage key={item.id} src={process.env.REACT_APP_SOCKETIO + item.avatar} />
                              <div className="d-flex flex-column">
                                <span className="fs-5 ms-5">
                                  {item.username} ({item.email})
                                </span>
                                <span className="fs-8 ms-5">{item.role}</span>
                              </div>
                            </div>
                          );
                        })}
                    {totalPages > currentPage &&
                      (loadingUsersToBeAdded ? (
                        <Loader />
                      ) : (
                        <div className="min-w-125px py-3 mb-3 d-flex">
                          <button className="btn btn-sm staticgreen ms-auto" onClick={NextPage}>
                            View More
                            <MdNavigateNext size={20} />
                          </button>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div id="zindex" className="card rounded mb-6 ">
        <div className="card-body p-2 px-5  pb-0 pt-5">
          {/* <div className="container"> */}
          <div className="accordion" id="responsiveAccordion">
            <div className="card">
              <div className=" d-sm-block d-md-none" id="headingOne">
                <h2 className="mb-0 d-md-none">
                  <button
                    // className=" btn-link d-flex w-100 collapsed"
                    className={`btn-link d-flex w-100 ${isProjectHeaderOpen ? "" : "collapsed"}`}
                    type="button"
                    // data-toggle="collapse"
                    data-target="#collapseOne"
                    // aria-expanded="true"
                    aria-expanded={isProjectHeaderOpen ? "false" : "true"}
                    aria-controls="collapseOne"
                    style={{
                      flexWrap: "wrap",
                    }}
                    onClick={toggleAccordion}
                  >
                    <div className="d-flex flex-wrap flex-sm-nowrap ">
                      <div className="d-flex flex-center flex-shrink-0 bg-light rounded  mb-4 c-img-size-50px">
                        {/* <img
                          className="h-100 w-100 "
                          src={
                            data
                              ? process.env.REACT_APP_SOCKETIO + data.logo
                              : "/assets/media/gif/loading.gif"
                          }
                          alt="logo"
                        /> */}
                        <LazyImage style={"h-100 w-100"} src={data ? process.env.REACT_APP_SOCKETIO + data.logo : ""} />
                      </div>
                      <div className="flex-grow-1">
                        <div className="row">
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 w-auto">
                            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                              <div className="d-flex flex-column">
                                <div className="d-flex align-items-center mb-1 flex-wrap">
                                  <div className="d-flex flex-column">
                                    <Link className="fs-2 ms-5 fw-bold me-3 cursor-default" style={{ color: "rgb(38, 72, 88)" }}>
                                      {data ? (data.name.length > 10 ? data.name.slice(0, 15) + "..." : data.name) : ""}
                                    </Link>
                                    <span className="fs-8 ms-5 mt-4">
                                      <span className="badge badge-light-success fs-5 me-3 ">{data ? data.projectCode : ""}</span>
                                      <span className="badge badge-light-success fs-5 me-auto">{data ? data.status : ""}</span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img
                      src="/assets/icons/down-arrow.png"
                      height={20}
                      width={20}
                      alt="arrow"
                      // className="ms-auto accordian-arrow"
                      className={`ms-auto accordian-arrow ${isProjectHeaderOpen ? "rotate" : ""}`}
                    />
                  </button>
                </h2>
                <div className="mb-0 d-none d-md-block" aria-expanded="true">
                  <h2
                    style={{
                      color: "#264858",
                    }}
                  >
                    {/* {data.name} */}
                  </h2>
                </div>
              </div>

              <div
                id="collapseOne"
                // className="collapse show"
                className={`collapse ${isProjectHeaderOpen ? "show" : ""}`}
                aria-labelledby="headingOne"
                data-parent="#responsiveAccordion"
              >
                <div className="card-body p-0 ">
                  <div className="d-flex flex-wrap flex-sm-nowrap">
                    <div className="d-flex flex-center flex-shrink-0 bg-light rounded me-7 mb-4 c-img-size-50px d-none d-md-block">
                      {/* <img
                        className="h-100 w-100"
                        src={
                          data
                            ? process.env.REACT_APP_SOCKETIO + data.logo
                            : "/assets/media/gif/loading.gif"
                        }
                        alt="logo"
                      /> */}
                      <LazyImage style={"h-100 w-100"} src={data ? process.env.REACT_APP_SOCKETIO + data.logo : ""} />
                    </div>
                    <div className="flex-grow-1">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 w-auto">
                          <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                            <div className="d-flex flex-column">
                              <div className="d-flex align-items-center mb-1 flex-wrap d-none d-md-block">
                                <Link className="fs-2 fw-bold me-3 cursor-default " style={{ color: "rgb(38, 72, 88)" }}>
                                  {data ? data.name : ""}
                                </Link>
                                <span className={data ? "badge badge-light-success fs-8 me-3 " : "badge fs-8 me-3 "}>{data ? data.projectCode : ""}</span>
                                <span className={data ? `badge badge-light-success fs-8 me-auto` : "badge fs-8 me-auto"}>{data ? data.status : ""}</span>
                              </div>
                              <div className="d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-400">{data ? (data.description.length > 40 ? data.description.slice(0, 35) + "..." : data.description) : ""}</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 w-auto ms-auto">
                          <div className="d-flex flex-wrap justify-content-end">
                            <div className="d-flex flex-wrap">
                              {projectAdmin === ctx.email && (
                                <div className="min-w-125px py-3  mb-3">
                                  <Button onClick={inviteMembersApi} filled={true}>
                                    <div className="d-flex gap-3">
                                      <MdOutlineGroupAdd size={20} />
                                      Invite Members
                                    </div>
                                  </Button>
                                </div>
                              )}
                              <div className="min-w-125px py-3 px-4  mb-3">
                                <Button onClick={viewMembersApi} filled={false}>
                                  <div className="d-flex gap-3">
                                    <MdGroups size={20} />
                                    View Members
                                  </div>
                                </Button>
                              </div>
                              <div className="border border-gray-300 border-dashed rounded min-w-125px py-1 px-4 me-6 mb-3">
                                <div className="d-flex align-items-center">
                                  <div className="fs-4 fw-bold">{new Date(formattedDate).toLocaleDateString("en-us")}</div>
                                </div>
                                <div className="fw-semibold fs-6 text-gray-400">Project Start Date</div>
                              </div>
                              <div className="border border-gray-300 border-dashed rounded min-w-125px py-1 px-4 mb-3">
                                <div className="d-flex align-items-center">
                                  <div className="fs-4 fw-bold" data-kt-countup="true" data-kt-countup-value={data ? data.membersLength : 0}>
                                    {data ? data.membersLength : 0}
                                  </div>
                                </div>
                                <div className="fw-semibold fs-6 text-gray-400">Total Members</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="separator" />
          <div>
            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
              <li className="nav-item">
                <button id="Documents" className={cn("nav-link text-active-primary py-2 me-6 fs-4 fw-bold", documentsNavItem && "active staticactive")} onClick={handleClick}>
                  Documents
                </button>
              </li>
              <li className="nav-item">
                <button id="Communication" className={cn("nav-link text-active-primary py-2 me-3 fs-4 fw-bold", communicationNavItem && "active staticactive")} onClick={handleClick}>
                  Communication
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <div className="card rounded bg-light min-h-350px">
          <div className="card-body c-m-card-body p-5 rounded">
            {documentsNavItem && (
              <Suspense fallback={<PageLoader />}>
                <FileManager projectid={projectId} isProjectHeaderOpen={isProjectHeaderOpen} setIsProjectHeaderOpen={setIsProjectHeaderOpen} />
              </Suspense>
            )}
            {communicationNavItem && (
              <UsersContext.Provider
                value={{
                  ...ctx,
                  projectId,
                }}
              >
                <Suspense fallback={<PageLoader />}>
                  <Chatapp />
                </Suspense>
              </UsersContext.Provider>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Project;
