import React, { Suspense, useContext, useEffect, useState } from "react";
import { UsersContext } from "../../context/usersContext";
import Footer from "./Footer";
import { ToastContainer } from "react-toastify";
import SideMenu from "./SideMenu";
import NotificationMenu from "./NotificationMenu";
import { Outlet } from "react-router-dom";
import LazyImage from "../../components/LazyImage";
import PageLoader from "../../components/Loader/PageLoader";
// import UserManagement from "../Auth/UserManagement";
const RootLayout = ({}) => {
  const ctx = useContext(UsersContext);

  const [isFirstLogin, setIsFirstLogin] = useState();

  useEffect(() => {
    localStorage.removeItem("projectid");
  }, []);

  useEffect(() => {
    setIsFirstLogin(ctx.isFirstLogin);
  }, [ctx]);

  function getGreeting() {
    const hour = new Date().getHours();
    if (hour < 12) {
        return "Good Morning";
    } else if (hour < 18) {
        return "Good Afternoon";
    } else {
        return "Good Evening";
    }
}

  return (
    <>
      <div
        id="kt_app_body"
        data-kt-app-header-fixed-mobile="true"
        data-kt-app-toolbar-enabled="true"
        className="app-default"
      >
        {/* <div
          id="kt_app_toolbar"
          className="app-toolbar py-6 header-background  position-absolute top-0 w-100 "
        ></div> */}
        <div
          id="kt_app_toolbar"
          className="app-toolbar  header-background user-select-none position-absolute top-0 w-100 "
        >
          <img src="/assets/icons/header.png" alt="Logo" width="100%" />
        </div>

        <div className="d-flex flex-column h-100">
          <div
            className="d-flex flex-column flex-root app-root"
            id="kt_app_root"
            style={{ height: "100vh" }}
          >
            <div className="d-flex h-100">
              <div className="4">
                <SideMenu />
              </div>
              <div className="w-100 h-100 overflow-auto">
                <div className="d-flex flex-column h-100">
                  <div className=" d-flex align-items-center symbol symbol-35px symbol-md-40px user-select-none px-5 pe-10 pe-sm-15" style={{height:75}}>
                    

                    <div className="d-flex flex-column ps-15 ps-lg-1">
                      <div>
                        <span className="d-none d-md-block text-light me-6 fs-2 ms-3">
                        {getGreeting()}, {ctx.username}!
                        </span>

                        <span className="d-md-none text-light me-6 fs-1 ms-3">
                        {ctx.username}
                        </span>
                      </div>
                      {/* <div>
                        <span className="text-light me-6 fs-6 ms-3">
                          {ctx.role}
                        </span>
                      </div> */}
                    </div>
                    <div className="d-flex gap-10 ms-auto text-white symbol">
                      <NotificationMenu />
                      <LazyImage
                      style="symbol symbol-circle h-40px w-40px img-cover"
                      src={process.env.REACT_APP_SOCKETIO + ctx.avatar}
                    />
                    </div>
                  </div>
                  <div
                    className="h-100 px-7 my-5 overflow-scroll"
                    style={{ scrollbarWidth: "none" }}
                  >
                    <div
                      id="kt_app_content"
                      className="d-flex flex-column h-100"
                    >
                      <Outlet />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
            <span className="svg-icon">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.5"
                  x={13}
                  y={6}
                  width={13}
                  height={2}
                  rx={1}
                  transform="rotate(90 13 6)"
                  fill="currentColor"
                />
                <path
                  d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </div>

          <Footer />
        </div>
      </div>
      {/* Your component content */}

      <ToastContainer />
    </>
  );
};

export default RootLayout;
