import React, { useEffect, useRef, useState } from "react";

function LazyImage({ src, style = "rounded-circle img-cover me-3 w-35px h-35px" }) {
  const [isVisible, setIsVisible] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // When image is in view
        if (entry.isIntersecting && imageRef.current) {
          setIsVisible(true);
          observer?.unobserve(imageRef.current);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    if (imageRef.current && imageRef.current) {
      observer?.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer?.unobserve(imageRef.current);
      }
    };
  }, []);
  return (
    <img
      ref={imageRef}
      src={isVisible ? src : "/assets/media/gif/loading.gif"}
      alt=""
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = "/assets/media/gif/loading.gif";
      }}
      // height={80}
      // width={100}
      className={style}
    />
  );
}

export default LazyImage;
